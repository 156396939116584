import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import Root from './Routes/Root';
import WhoAmI from './Routes/WhoAmI';
import Blog from './Routes/Blog';
import Article from './Routes/Article';
import ErrorPage from './Routes/ErrorPage';

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/whoami",
        element: <WhoAmI />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/blog/:articleId",
        element: <Article />,
      }
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);
reportWebVitals();
