import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDpgPR35T5NV-M4YbiaiGp9IiAUorWA6eE",
  authDomain: "rolam-co.firebaseapp.com",
  projectId: "rolam-co",
  storageBucket: "rolam-co.appspot.com",
  messagingSenderId: "35925011344",
  appId: "1:35925011344:web:726d8c3996c49b38d6e115",
  measurementId: "G-E43T74P2P2"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app);