import React, { useEffect, useState } from 'react';

import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

import 'animate.css';
import { BarLoader } from 'react-spinners';

function WhoAmI() {
  const [keyword, setKeyword] = useState('');
  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    const pickRandKeyword = () => {
      const wordList = ['Simple', 'Clean', 'Optimal', 'Scalable', 'Intuitive', 'Standardized', 'Modern', 'No-nonsense'];
      const index = Math.floor(Math.random() * wordList.length);
      setKeyword(wordList[index]);
    }
    const fetchPageContent = async () => {
      const docRef = doc(db, 'pages', 'whoami');
      const docSnap = await getDoc(docRef)
      const docData = docSnap.data();
      setTimeout(() => {
        setPageContent(docData.pageContent);
      }, 500);
    }
    fetchPageContent();
    pickRandKeyword();
  }, []);

  return (
    <div className="screen animate__animated animate__fadeIn">
      {pageContent !== null ?
        <>
          <div className='hero animate__animated animate__fadeIn'>
            <h1>Helping Startups Build <span className='hero-accent'>{keyword}</span> Interfaces</h1>
            <a href='mailto:rholem.abdellah@gmail.com'>
              <button>Say hello</button>
            </a>
          </div>
          {pageContent.sections.map((section) => {
            return (
              <section className='animate__animated animate__fadeIn animate__faster'>
                <div className='section-heading'>
                  <label>{section.label}</label>
                  <h2>{section.heading}</h2>
                </div>
                <div className='section-content'>
                  {
                    section.image !== "" &&
                    <div className='image-container'>
                      <img className='section-illustration' src={section.image} alt='illustration' />
                    </div>
                  }
                  {section.paragraphs.map((paragraph) => {
                    return (<p>{paragraph}</p>);
                  })}
                </div>
              </section>);
          })}
        </>
        :
        <div className='loader-container'>
          <BarLoader
            color='var(--green-accent)'
            loading={true}
            cssOverride={{
              borderRadius: 8,
              backgroundColor: 'var(--green-medium)',
            }}
            width={160}
            height={2}
            speedMultiplier={1.8}

          />
        </div>
      }
    </div>
  );
}

export default WhoAmI;