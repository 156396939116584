import { Outlet, NavLink } from "react-router-dom";

function Root() {

  const hideTLDR = () => {
    if (window.innerWidth <= 450) {
      const tLine1 = document.getElementById('t-line-1');
      const tLine2 = document.getElementById('t-line-2');
      const terminal = document.getElementById('terminal');
      tLine1.style.display = 'none';
      tLine2.style.display = 'none';
      terminal.style.flex = '1';
    }
  }

  return (
    <div className="main-wrapper">
      <div className="side-bar">
        <div id="terminal" className="terminal">
          <div className="t-window">
            <div id="t-line-1" className="t-line">rolam@mac ~ % cat tldr.txt</div>
            <div id="t-line-2" className="t-line">I'm Rolam, a designer-engineer. I build interfaces that enable users to get things done without friction.</div>
            <div className="t-line">rolam@mac ~ % cd <div className="t-cursor"></div></div>
            <div className="t-nav">
              <NavLink
                to="/whoami"
                className={({ isActive }) => isActive ? "t-nav-link active" : "t-nav-link"}
                onClick={() => hideTLDR()}
              >
                whoami
              </NavLink>
              <NavLink
                to="/blog"
                className={({ isActive }) => isActive ? "t-nav-link active" : "t-nav-link"}
                onClick={() => hideTLDR()}
              >
                blog
              </NavLink>
              <a
                className="t-nav-link" href="https://www.uniqium.com"
                target="__blank"
                onClick={() => hideTLDR()}
              >
                uniqium
              </a>
              <a
                className="t-nav-link" href="https://www.air.chat/rolamtalks"
                target="__blank"
                onClick={() => hideTLDR()}
              >
                airchat
              </a>
            </div>
          </div>
        </div>
        <p className="copyright">© 2024 Rolam. All rights resereved.</p>
      </div>
      <Outlet />
    </div>
  );
}

export default Root;
