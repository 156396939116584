import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

import 'animate.css';
import { BarLoader } from 'react-spinners';

function Blog() {
  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    const fetchPageContent = async () => {
      const docRef = doc(db, 'pages', 'blog');
      const docSnap = await getDoc(docRef)
      const docData = docSnap.data();
      setTimeout(() => {
        setPageContent(docData.pageContent);
      }, 500);
    }
    fetchPageContent();
  }, []);

  return (
    <div className="screen animate__animated animate__fadeIn">
      {pageContent !== null ?
        <>
          <div className='hero hr-small animate__animated animate__fadeIn'>
            <label>Blog</label>
            <h1>Observing Human-Machine Interactions</h1>
          </div>
          <div className='articles-grid animate__animated animate__fadeIn'>
            {pageContent.articles.map((article) => {
              return (
                <div className='article-card'>
                  <div className='ac-top'>
                    <label>{article.publicationDate}</label>
                    <h2>{article.articleHeading}</h2>
                    <p>{article.articleSnippet}</p>
                  </div>
                  <Link to={`/blog/${article.articleId}`}>
                    <button>
                      Read more
                    </button>
                  </Link>
                </div>
              );
            })}
          </div>
        </>
        :
        <div className='loader-container'>
          <BarLoader
            color='var(--green-accent)'
            loading={true}
            cssOverride={{
              borderRadius: 8,
              backgroundColor: 'var(--green-medium)',
            }}
            width={160}
            height={2}
            speedMultiplier={1.8}

          />
        </div>
      }
    </div>
  );
}

export default Blog;