import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

import 'animate.css';
import { BarLoader } from 'react-spinners';

function Article() {
  const [articleContent, setArticleContent] = useState(null);

  const { articleId } = useParams();

  useEffect(() => {
    const fetchArticleContent = async () => {
      const docRef = doc(db, 'pages', 'blog', 'articles', articleId);
      const docSnap = await getDoc(docRef)
      const docData = docSnap.data();
      console.log(docData);

      setTimeout(() => {
        setArticleContent(docData);
      }, 500);
    }
    fetchArticleContent();
  }, [articleId]);

  return (
    <div className="screen animate__animated animate__fadeIn">
      {articleContent !== null ?
        <>
          <div className='hero hr-small animate__animated animate__fadeIn'>
            <label>{articleContent.publicationDate}</label>
            <h1>{articleContent.articleHeading}</h1>
          </div>
          {articleContent.sections.map((section) => {
            return (
              <section className='animate__animated animate__fadeIn animate__faster'>
                <div className='section-heading'>
                  <label>{section.label}</label>
                  <h2>{section.heading}</h2>
                </div>
                <div className='section-content'>
                  {
                    section.image !== "" &&
                    <div className='image-container'>
                      <img className='section-illustration' src={section.image} alt='illustration' />
                    </div>
                  }
                  {section.paragraphs.map((paragraph) => {
                    return (<p>{paragraph}</p>);
                  })}
                </div>
              </section>);
          })}
        </>
        :
        <div className='loader-container'>
          <BarLoader
            color='var(--green-accent)'
            loading={true}
            cssOverride={{
              borderRadius: 8,
              backgroundColor: 'var(--green-medium)',
            }}
            width={160}
            height={2}
            speedMultiplier={1.8}

          />
        </div>
      }
    </div>
  );
}

export default Article;